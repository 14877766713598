<template>
  <CDropdown
    inNav
    class="c-header-nav-items d-md-down-none mx-2"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-globe-alt"/>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ $t('lang') }}</strong>
    </CDropdownHeader>
    <CDropdownItem v-for="(lang, index) in langsOptions" v-bind:key="index" :disabled="lang.disabled" @click="updateUserLocale(lang)">
      <div class="message">
        <div class="text-muted">
          <img :src="lang.flag" class="mr-2" height="18"> {{ lang.label }}
        </div>
      </div>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownLang',
  data () {
    return {
      langsOptions: [
        { value: 'tw', label: '繁體中文', flag: 'img/flags/taiwan.svg' },
        { value: 'en', label: 'English', flag: 'img/flags/united-states-of-america.svg' }
      ]
    }
  },
  mounted: function () {
  },
  methods: {
    updateUserLocale (lang) {
      const loader = this.$loading.show()
      this.$store.dispatch('updateUserLocale', { locale: lang.value }).then(res => {
        if (this.$store.getters.locale !== lang.value) {
          this.$store.commit('SET_LANG', lang.value)
          location.reload()
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>

<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar shadow">
          <img
            :src="$store.getters.avatar"
            class="c-avatar-img"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ $t('settings') }}</strong>
    </CDropdownHeader>
    <CDropdownItem to="/profile">
      <CIcon name="cil-address-book" /> {{ $t('profile') }}
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="logout()">
      <CIcon name="cil-account-logout" /> {{ $t('logout') }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
    }
  },
  mounted: function () {
  },
  methods: {
    logout () {
      const loader = this.$loading.show()
      this.$store.dispatch('logout').then(res => {
        loader.hide()
        this.$router.push({ path: '/login' })
      }).catch(e => {
        loader.hide()
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
<template>
  <CFooter :fixed="false">
    <div>
      <a href="/" target="_blank">Parking</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }} Chainmeans. Ltd.</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Powered by</span>
      <a href="https://www.chainmeans.com" target="_blank">Chainmeans.</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

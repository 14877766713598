import i18n from '@/lang/i18n'

export default [
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('dashboard'),
    to: '/dashboard',
    icon: 'cil-speedometer'
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('system_management')]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('organization'),
    route: '/organization',
    icon: 'cil-people',
    items: [
      {
        name: i18n.t('companies'),
        to: '/organization/company',
        icon: 'cil-building',
        role: 'root',
        badge: {
          color: 'secondary',
          text: i18n.t('manager')
        }
      },
      {
        name: i18n.t('users'),
        to: '/organization/user',
        icon: 'cil-user',
        role: 'root',
        badge: {
          color: 'secondary',
          text: i18n.t('manager')
        }
      }
    ]
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('operation_management')]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('device'),
    route: '/device',
    icon: 'cil-cog',
    items: [
      {
        name: i18n.t('device_search'),
        to: '/device/search',
        icon: 'cil-search',
        role: 'device-search'
      },
      {
        name: i18n.t('qc_report'),
        to: '/device/qcReport',
        icon: 'cil-description',
        role: 'device-qcReport'
      },
      {
        name: i18n.t('qr_code'),
        to: '/device/qrCode',
        icon: 'cil-qr-code',
        role: 'device-qrCode'
      }
    ]
  },
]
